import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { DefaultHeader } from "components/headers/default-header"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faPlus, faEquals } from "@fortawesome/free-solid-svg-icons"

const StoreCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="Digital x Real Event Support | Easy QR Code Campaign"
          pagedesc="Go to a state-of-the-art commercial facility! QR Code Campaigns for Digital Campaign Beginners"
          pagepath={location.pathname}
        />
        <main className="store font-noto">
          <section className="store__fv">
            <div className="md:pt-6 pt-14 pb-20 md:px-24 px-2">
              <div className="text-center w-1/2 md:w-full mx-auto">
                <StaticImage
                  src="../../../images/product/store/top-subtitle.png"
                  alt="Go to a state-of-the-art commercial facility!"
                  layout="constrained"
                />
              </div>
              <div className="w-10/12 mx-auto text-center md:block hidden md:mb-2">
                <StaticImage
                  src="../../../images/product/store/top_text_title.png"
                  alt="Digital x Real Event Support. Easy QR Code Campaign"
                  layout="constrained"
                />
              </div>
              <div className="text-center md:hidden block mx-auto mb-1">
                <StaticImage
                  src="../../../images/product/store/top_text_title_sp.png"
                  alt="Digital x Real Event Support. Easy QR Code Campaign"
                  layout="constrained"
                />
              </div>
              <div className="md:flex hidden md:gap-10 md:mb-6 circle-container mx-auto">
                <div>
                  <StaticImage
                    src="../../../images/product/store/feat_01.png"
                    alt="Recommended for beginners of digital campaigns"
                    layout="constrained"
                  />
                </div>
                <div>
                  <StaticImage
                    src="../../../images/product/store/feat_02.png"
                    alt="Minimum price 130,000 yen"
                    layout="constrained"
                  />
                </div>
                <div>
                  <StaticImage
                    src="../../../images/product/store/feat_03.png"
                    alt="Integrated ordering from planning to printing is possible!!"
                    layout="constrained"
                  />
                </div>
              </div>
              <div className="block md:hidden text-center mb-6">
                <StaticImage
                  src="../../../images/product/store/sp_feat.png"
                  alt="Recommended for beginners of digital campaigns. Minimum price 130,000 yen. Integrated ordering from planning to printing is possible"
                  layout="constrained"
                />
              </div>
              <div className="text-center">
                <a
                  href="https://hub.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer"
                  className="store__fv-cta-btn text-white inline-block text-xl md:text-4xl font-bold px-20 py-4 md:py-5"
                >
                  Download Materials
                </a>
              </div>
            </div>
          </section>
          <section className="store__merit">
            <div className="container md:py-32 py-20 md:px-0 px-4">
              <h2 className="text-center md:mb-20 mb-8">
                <StaticImage
                  src="../../../images/product/store/title_merit.png"
                  alt="Contribute to purchase promotion and notification!!"
                  layout="constrained"
                />
              </h2>
              <div className="bg-white px-4 py-6 md:py-12 md:px-20">
                <div className="md:flex md:gap-20 items-center">
                  <div className="md:w-2/6 w-1/2 mx-auto mb-6 md:mb-0">
                    <StaticImage
                      src="../../../images/product/store/phone.png"
                      alt=""
                      layout="constrained"
                    />
                  </div>
                  <div className="md:flex-1">
                    <div className="md:mb-14 mb-6">
                      <h3 className="font-bold md:text-4xl text-2xl mb-2">
                        Send customers to stores
                      </h3>
                      <p className="md:text-xl font-bold">
                        Increase your ability to attract customers by holding a
                        campaign that they can only participate in if they
                        actually come to your store!
                      </p>
                    </div>
                    <div className="md:mb-14 mb-6">
                      <h3 className="font-bold md:text-4xl text-2xl mb-2">
                        Manage Codes
                      </h3>
                      <p className="md:text-xl font-bold">
                        PARKLoT's QR code campaigns can also issue and manage QR
                        codes in variable format.
                      </p>
                    </div>
                    <div>
                      <h3 className="font-bold md:text-4xl text-2xl mb-2">
                        Can be embedded in flyers
                      </h3>
                      <p className="md:text-xl font-bold">
                        QR codes can be embedded in flyers. You can participate
                        in the campaign with just one smartphone, without having
                        to go through the process of downloading an app or
                        following on social networking sites.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="store__custom md:py-32 py-20">
            <div className="container px-4 md:px-0">
              <h2 className="text-center md:mb-20 mb-8">
                <StaticImage
                  src="../../../images/product/store/title_custom.png"
                  alt="Full range of detailed customization"
                  layout="constrained"
                />
              </h2>
              <ul className="flex w-full flex-wrap mx-auto md:mb-20 mb-8 text-center justify-center">
                <li className="item item--pink">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    Variable Publish&nbsp;
                    <br className="md:block hidden" />
                    and print QR codes
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_print.png"
                      alt="Variable Publish and print QR codes"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    A different QR code can be printed&nbsp;
                    <br />
                    on each sheet
                    <br />
                    We accept orders for everything&nbsp;
                    <br className="hidden md:block" />
                    from QR code issuance to printing.
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    QR Code Issuance
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_qr.png"
                      alt="QR Code Issuance"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    You can participate with&nbsp;
                    <br className="hidden md:block" />
                    your smartphone more easily&nbsp;
                    <br className="hidden md:block" />
                    than with a serial code.
                  </p>
                </li>
                <li className="item item--yellow">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    Lottery can be conducted&nbsp;
                    <br />
                    within your LP
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_lp.png"
                      alt="Lottery can be conducted within your LP"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    The drawing can also be held&nbsp;
                    <br className="hidden md:block" />
                    on the campaign's LP site.
                  </p>
                </li>
                <li className="item item--yellow">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    Prevention of duplicate lotteries
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_prevention.png"
                      alt="Prevention of duplicate lotteries"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    Once the lottery has been drawn&nbsp;
                    <br className="hidden md:block" />
                    QR codes can be disabled
                  </p>
                </li>
                <li className="item item--pink">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    Free to set up the production
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_lottery.png"
                      alt="Free to set up the production"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    Free setting of images to be used&nbsp;
                    <br className="hidden md:block" />
                    for the performance&nbsp;
                    <br className="hidden md:block" />
                    at the time of the lottery drawing
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    Automatic lottery function
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_automatic.png"
                      alt="Automatic lottery function"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    Automatic drawing of winners
                    <br className="hidden md:block" />
                    Choice of later or immediate drawings
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    DM Sending Functions
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_dm.png"
                      alt="DM Sending Functions"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    Send unique gift code and URL&nbsp;
                    <br className="hidden md:block" />
                    as notification of winning results
                  </p>
                </li>
                <li className="item item--yellow">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    Customize Sort Order
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_sort.png"
                      alt="Customize Sort Order"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    Customize the order of posts&nbsp;
                    <br className="hidden md:block" />
                    by date and time,&nbsp;
                    <br className="hidden md:block" />
                    number of likes received, etc.
                  </p>
                </li>
                <li className="item item--pink">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    Lottery screen design&nbsp;
                    <br />
                    customization
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_design.png"
                      alt="Lottery screen design customization"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    Providing the right design for your&nbsp;
                    <br className="hidden md:block" />
                    company's website or campaign site
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    Join by registering&nbsp;
                    <br className="hidden md:block" />
                    as a friend on LINE
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_design.png"
                      alt="Join by registering as a friend on LINE"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    Join the campaign by registering&nbsp;
                    <br className="hidden md:block" />
                    as a LINE friend and sending a QR code
                  </p>
                </li>
              </ul>
              <div className="text-center md:mb-10 mb-8">
                <a
                  href="https://hub.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer"
                  className="store__custom-cta-btn inline-block rounded-xl md:py-6 py-4 md:px-24 w-full md:w-auto bg-white font-bold text-xl md:text-4xl"
                >
                  Download Materials
                </a>
              </div>
              {/* <p className="md:text-xl font-bold text-center">
                Please see "Fee Plans" for the amount.
              </p> */}
            </div>
          </section>
          <section className="store__howto">
            <div className="container md:py-32 py-20 md:px-0 px-4">
              <h2 className="text-center md:mb-24 mb-12">
                <StaticImage
                  src="../../../images/product/store/title_howto.png"
                  alt="You can use it in the following ways"
                  layout="constrained"
                />
              </h2>
              <div className="case-container md:mb-28 mb-12 md:w-4/5">
                <div className="case-title">
                  <h3 className="z-10 text-xl md:text-3xl text-white inline-block text-center">
                    case1
                  </h3>
                </div>
                <div className="case-img mb-6 md:mb-0">
                  <StaticImage
                    src="../../../images/product/store/img_case01.png"
                    alt="Organizing a campaign in which you can participate only with a flyer"
                    layout="constrained"
                  />
                </div>
                <h4 className="font-bold text-2xl md:text-4xl md:mb-6 mb-2 pt-3">
                  Organizing a campaign&nbsp;
                  <br />
                  in which you can participate&nbsp;
                  <br />
                  only with a flyer
                </h4>
                <p className="md:text-xl">
                  1.Distribute lottery tickets with QR codes at stores.
                  <br />
                  2.Read the QR code with your smartphone.
                  <br />
                  3.Go to the site and draw lots!!
                </p>
              </div>
              <div className="case-container md:w-4/5">
                <div className="case-title">
                  <h3 className="z-10 text-xl md:text-3xl text-white inline-block text-center">
                    case2
                  </h3>
                </div>
                <div className="case-img mb-6 md:mb-0">
                  <StaticImage
                    src="../../../images/product/store/img_case02.png"
                    alt="You can draw lots whenever you like."
                    layout="constrained"
                  />
                </div>
                <h4 className="font-bold text-2xl md:text-4xl md:mb-6 mb-2 pt-3">
                  You can draw lots
                  <br />
                  whenever you like
                </h4>
                <p className="md:text-xl">
                  You don't have to stand in line&nbsp;
                  <br />
                  to participate in the lottery!!
                  <br />
                  <br />
                  Challenge the lottery at café time&nbsp;
                  <br />
                  or on the way home
                </p>
              </div>
            </div>
          </section>
          {/* <section className="store__plan">
            <div className="container md:py-32 py-20 md:px-0 px-4">
              <h2 className="text-center md:mb-24 mb-12">
                <StaticImage
                  src="../../../images/product/store/title_plan.png"
                  alt="Rate Plans"
                  layout="constrained"
                />
              </h2>
              <div className="bg-white md:py-12 md:px-14 py-6 px-3 mb-10">
                <h3 className="md:text-3xl text-2xl font-bold text-center mb-8">
                  Basic cost
                </h3>
                <div className="flex items-center justify-center md:gap-6 gap-2 mb-4 md:mb-5">
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">Initial cost</p>
                    <p className="font-bold text-xl md:text-4xl">80,000yen</p>
                  </div>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="text-2xl md:text-5xl text-text"
                  />
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">monthly amount</p>
                    <p className="font-bold text-xl md:text-4xl">50,000yen</p>
                  </div>
                  <FontAwesomeIcon
                    icon={faEquals}
                    className="text-2xl md:text-5xl text-text"
                  />
                  <div className="price-box flex-1 price-box--emphasis">
                    <p className="text-sm mb-2 md:text-2xl">total amount</p>
                    <p className="font-bold text-xl md:text-4xl">130,000yen</p>
                  </div>
                </div>
                <p className="md:text-xl text-sm">
                  Minimum of 20,000 pieces available
                  <br />
                  *The amount varies depending on the number of sheets.
                  <br />
                  *Printing cost: 90,000 yen and up (printing cost notation
                  method to be considered)
                  <br />
                  *Delivery time is about 1 week.
                </p>
              </div>
              <div className="bg-white md:py-12 md:px-14 py-6 px-3 md:mb-20 mb-8">
                <h3 className="md:text-3xl text-2xl font-bold text-center mb-8">
                  Options
                </h3>
                <div className="flex flex-wrap md:gap-10 gap-2 justify-center mb-2 md:mb-10">
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">Collecting Likes</p>
                    <p className="font-bold text-xl md:text-4xl">30,000yen</p>
                  </div>
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">
                      Collecting replies
                    </p>
                    <p className="font-bold text-xl md:text-4xl">30,000yen</p>
                  </div>
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">
                      Customize
                      <br />
                      Design
                    </p>
                    <p className="font-bold text-xl md:text-4xl">30,000yen</p>
                  </div>
                </div>
                <div className="flex flex-wrap md:gap-10 gap-2 justify-center">
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">
                      Customize
                      <br />
                      Sort Order
                    </p>
                    <p className="font-bold text-xl md:text-4xl">30,000yen</p>
                  </div>
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">
                      Campaign LP
                      <br />
                      creation agency
                    </p>
                    <p className="font-bold text-xl md:text-4xl">100,000yen~</p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <a
                  href="https://hub.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer"
                  className="store__custom-cta-btn inline-block rounded-xl md:py-6 py-4 md:px-24 w-full md:w-auto bg-white font-bold text-xl md:text-4xl"
                >
                  Download Materials
                </a>
              </div>
            </div>
          </section> */}
        </main>
      </Layout>
    </>
  )
}
export default StoreCampaign
